import React from "react"

export const Dribbble = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.4333 15.97C16.4883 15.95 16.5467 15.935 16.605 15.9183C16.4571 15.5905 16.3021 15.266 16.14 14.945C13.0067 15.8733 10.0067 15.8817 9.47167 15.8733C9.46667 15.915 9.46667 15.9583 9.46667 16C9.46667 17.575 10.0333 19.0883 11.0633 20.2767C11.4067 19.7233 13.2267 17.0083 16.4333 15.97ZM12.0383 21.1883C12.9448 21.883 14.0179 22.3275 15.1501 22.4772C16.2823 22.627 17.4341 22.4768 18.49 22.0417C18.1838 20.3797 17.7252 18.7495 17.12 17.1717C13.585 18.4217 12.25 20.775 12.0383 21.1883ZM20.2583 11.045C19.488 10.3833 18.574 9.91024 17.5889 9.66346C16.6038 9.41667 15.5747 9.40293 14.5833 9.62333C15.4531 10.8051 16.2443 12.0427 16.9517 13.3283C19.09 12.5067 20.0567 11.3217 20.2583 11.045ZM15.5083 13.7733C14.79 12.5137 14.0027 11.2946 13.15 10.1217C12.2697 10.5506 11.4965 11.1711 10.8872 11.9376C10.2778 12.7041 9.84768 13.5973 9.62833 14.5517H9.65667C10.3333 14.5517 12.7383 14.4967 15.5083 13.7733ZM18.5233 16.8267C19.2983 18.9817 19.655 20.7717 19.7567 21.345C21.1771 20.3436 22.1416 18.8191 22.4383 17.1067C21.5752 16.8616 20.6822 16.7376 19.785 16.7383C19.355 16.7383 18.93 16.7683 18.5233 16.8267ZM16 0C7.16333 0 0 7.16333 0 16C0 24.8367 7.16333 32 16 32C24.8367 32 32 24.8367 32 16C32 7.16333 24.8367 0 16 0ZM16 23.8283C13.924 23.8266 11.9335 23.0013 10.4653 21.5337C8.99706 20.0661 8.17098 18.076 8.16833 16C8.1701 13.9235 8.99578 11.9325 10.4641 10.4641C11.9325 8.99578 13.9235 8.1701 16 8.16833C18.0761 8.17098 20.0664 8.997 21.5343 10.4652C23.0022 11.9334 23.8278 13.9239 23.83 16C23.8278 18.0758 23.0021 20.066 21.5341 21.5336C20.0661 23.0013 18.0758 23.8266 16 23.8283ZM17.5367 14.4567C17.6817 14.7567 17.8167 15.0517 17.94 15.3417L18.0583 15.625C18.52 15.57 19.0133 15.5433 19.5283 15.5433C20.5356 15.5454 21.5405 15.6413 22.53 15.83C22.492 14.4104 21.9936 13.0417 21.11 11.93C20.8433 12.2733 19.7467 13.535 17.5367 14.4567Z"
      fill="#F2F2F2"
    />
  </svg>
)

export const Github = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.025 15.915H15.975C13.99 15.915 12.2417 15.41 11.2067 16.3617C10.9255 16.6115 10.7014 16.9191 10.5499 17.2634C10.3983 17.6076 10.3227 17.9805 10.3283 18.3567C10.3283 21.4433 12.8 21.8233 15.9767 21.8233H16.025C19.2 21.8233 21.6733 21.4417 21.6733 18.3567C21.6733 17.6183 21.4133 16.93 20.795 16.3617C19.7583 15.41 18.01 15.915 16.025 15.915ZM13.3217 20.1333C12.7167 20.1333 12.2283 19.4533 12.2283 18.6167C12.2283 17.78 12.7167 17.1033 13.3217 17.1033C13.9267 17.1033 14.4167 17.78 14.4167 18.6167C14.4183 19.4533 13.9283 20.1333 13.3217 20.1333ZM18.6767 20.1333C18.0717 20.1333 17.5817 19.4533 17.5817 18.6167C17.5817 17.78 18.0717 17.1033 18.6767 17.1033C19.28 17.1033 19.77 17.78 19.77 18.6167C19.7717 19.4533 19.2817 20.1333 18.6767 20.1333ZM16 0C7.16333 0 0 7.16333 0 16C0 24.8367 7.16333 32 16 32C24.8367 32 32 24.8367 32 16C32 7.16333 24.8367 0 16 0ZM17.46 22.565C17.1733 22.565 16.6033 22.565 16 22.5683C15.3967 22.565 14.8267 22.565 14.54 22.565C13.2733 22.565 8.25333 22.4667 8.25333 16.4167C8.25333 15.0267 8.73 14.0083 9.51167 13.1583C9.38833 12.8517 9.38167 11.105 10.045 9.43167C10.045 9.43167 11.5717 9.59833 13.88 11.1833C14.3617 11.0483 15.1817 10.98 16 10.98C16.8183 10.98 17.6367 11.0483 18.1217 11.1817C20.43 9.59667 21.9567 9.43 21.9567 9.43C22.62 11.1033 22.615 12.8517 22.49 13.1567C23.2717 14.0067 23.7483 15.025 23.7483 16.415C23.7467 22.4683 18.7267 22.565 17.46 22.565Z"
      fill="#F2F2F2"
    />
  </svg>
)

export const Linkedin = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 0C7.16333 0 0 7.16333 0 16C0 24.8367 7.16333 32 16 32C24.8367 32 32 24.8367 32 16C32 7.16333 24.8367 0 16 0ZM12.0833 22.6317H8.84333V12.205H12.0833V22.6317ZM10.4433 10.925C9.42 10.925 8.75833 10.2 8.75833 9.30333C8.75833 8.38833 9.44 7.685 10.485 7.685C11.53 7.685 12.17 8.38833 12.19 9.30333C12.19 10.2 11.53 10.925 10.4433 10.925ZM23.9167 22.6317H20.6767V16.8533C20.6767 15.5083 20.2067 14.595 19.035 14.595C18.14 14.595 17.6083 15.2133 17.3733 15.8083C17.2867 16.02 17.265 16.32 17.265 16.6183V22.63H14.0233V15.53C14.0233 14.2283 13.9817 13.14 13.9383 12.2033H16.7533L16.9017 13.6517H16.9667C17.3933 12.9717 18.4383 11.9683 20.1867 11.9683C22.3183 11.9683 23.9167 13.3967 23.9167 16.4667V22.6317Z"
      fill="#F2F2F2"
    />
  </svg>
)
